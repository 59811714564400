<template>
  <div class="flex flex-col lg:flex-row my-12">
    <div class="md:basis-1/2 p-2">
      <div class="max-w-[500px] mx-auto mb-12 lg:mb-0">
        <div class="text-center min-h-[120px] font-titleSemiBold ">
          <h2 class="mb-0 leading-7 text-4xl sm:text-4.5xl uppercase">returning customer</h2>
          <p class="text-3xl sm:text-4xl">Sign into your account</p>
        </div>
        <V-Form
            @submit="login"
            :validation-schema="schemaLogin"
        >
          <div class="flex flex-col">
            <div class="mb-8">
              <front-field label="" class="text-xl">
                <front-control
                    v-model="formLogin.email"
                    type="text"
                    placeholder="example-email@gmail.com"
                    name="emailLogin"
                    borderbottom
                    borderradiusnone
                />
              </front-field>
            </div>
            <div class="mb-4">
              <front-field label="" class="text-xl">
                <front-control
                    v-model="formLogin.password"
                    type="password"
                    placeholder="PASSWORD"
                    name="passwordLogin"
                    borderbottom
                    borderradiusnone
                />
              </front-field>
            </div>
            <div class="mb-4">
              <front-field label="" class="text-xl">

                <div class="flex mt-2" >
                  <front-check-radio-picker
                      v-model="remember"
                      name="inquire_checkbox"
                      type="checkbox"
                      :options="{ one: ''}"
                  />
                <p class="ml-3 font-semibold" >
                 Remember me
                </p>
            </div>
              </front-field>
            </div>
            <div class="mb-4">
              <NuxtLink to="/reset-password" class="underline underline-offset-1 text-veryDarkGray text-lg">Forgot Password?
              </NuxtLink>
            </div>

            <div class="mb-4 text-center">
              <front-jb-button
                  :icon="mdiArrowRightBold"
                  label="SUBMIT"
                  class="rounded-r-none min-w-[300px] bg-blackLight p-2.5 font-sans"
                  type="submit"
                  color="blackNatonal"
              />
            </div>
          </div>
        </V-Form>
      </div>
    </div>
    <div class="md:basis-1/2 p-2">
      <div class="max-w-[500px] mx-auto ">
        <div class="text-center min-h-[120px] font-titleSemiBold">
          <h2 class="mb-0 leading-7 text-4xl sm:text-4.5xl uppercase ">New customer</h2>
          <p class="text-3xl sm:text-4xl">Create an account</p>
        </div>
        <V-Form
            @submit="register"
            :validation-schema="schemaRegister"
        >
          <div class="mb-4 flex flex-row ">
            <div class="basis-2/4 flex flex-col ">
              <front-field label="First Name *" upperText class=" text-black text-tiny">
                <div>
                  <front-control
                      v-model="formRegister.first_name"
                      type="text"
                      name="first_nameRegister"
                      borderbottom
                      borderradiusnone
                      class="text-xl font-semibold "
                  />
                  <p class="text-red-600" v-if="errors.first_name">
                    {{ errors.first_name[0] }}
                  </p>
                </div>
              </front-field>

            </div>
            <div class="basis-2/4 flex flex-col">
              <front-field label="Last Name *" upperText class="pl-1 text-tiny text-xl">
                <div>
                  <front-control
                      v-model="formRegister.last_name"
                      type="text"
                      name="last_nameRegister"
                      borderbottom
                      borderradiusnone
                      class="text-xl"
                  />
                  <p class="text-red-600" v-if="errors.last_name">
                    {{ errors.last_name[0] }}
                  </p>
                </div>
              </front-field>
            </div>


          </div>
          <div class="mb-4">
            <front-field label="E-mail *" upperText class=" text-tiny">
              <div>
                <front-control
                    v-model="formRegister.email"
                    type="text"
                    name="emailRegister"
                    placeholder="E-mail:examplex@gmail.com"
                    borderbottom
                    borderradiusnone
                    class="text-xl"
                />
                <p class="text-red-600" v-if="errors.email">
                  {{ errors.email[0] }}
                </p>
              </div>
            </front-field>
          </div>
          <div class="mb-4">
            <front-field label="Password *" upperText class="text-tiny" >
              <div>
                <front-control
                    v-model="formRegister.password"
                    type="password"
                    name="passwordRegister"
                    placeholder="Password"
                    borderbottom
                    borderradiusnone
                    class="text-xl"
                />
                <p class="text-red-600" v-if="errors.password">
                  {{ errors.password[0] }}
                </p>
              </div>
            </front-field>
          </div>
          <div class="mb-4">
            <front-field label="" upperText>
              <div>
                <front-control
                    v-model="formRegister.confirm_password"
                    type="password"
                    name="confirm_passwordRegister"
                    placeholder="*Confirm Password"
                    borderbottom
                    borderradiusnone
                />
                <p class="text-red-600" v-if="errors.confirm_password">
                  {{ errors.confirm_password[0] }}
                </p>
              </div>
            </front-field>
          </div>
          <div class="mb-4">
            <front-field>
              <front-jb-button
                  :icon="mdiArrowRightBold"
                  label="SUBMIT"
                  color="blackNatonal"
                  class="rounded-r-none w-full bg-blackLight p-2.5 font-semibold font-sans"
                  type="submit"
              />
            </front-field>
          </div>
        </V-Form>
      </div>
    </div>
  </div>
</template>
<script setup>
import {mdiArrowRightBold} from '@mdi/js'
import {AuthenticationUser, Page} from "~/composables/nhTypes";
import * as Yup from "yup";
import {computed, onMounted, ref} from "vue";
import {navigateTo, useHead} from "nuxt/app";
import {http} from "~/utils/http";
import Swal from "sweetalert2";
import { useMainStore } from '~/stores/main'
import {useSiteTitle} from "../composables/state";

const schemaLogin = Yup.object().shape({
  'emailLogin': Yup.string().trim().required().nullable().label('Email'),
  'passwordLogin': Yup.string().trim().required().nullable().label('Password'),
});
const router = useRouter();

const schemaRegister = Yup.object().shape({
  'first_nameRegister': Yup.string().trim().required().nullable().label('First Name'),
  'last_nameRegister': Yup.string().trim().required().nullable().label('Last Name'),
  'emailRegister': Yup.string().trim().required().nullable().email().label('Email'),
  'passwordRegister': Yup.string().trim().required().nullable().min(8, 'Must be at least 8 characters!').label('Password'),
  'confirm_passwordRegister': Yup.string().trim().required().nullable().label('Confirm Password').test("checkPassword", "The password and the confirmed password must be the same!",
      async function () {
        let status = true;
        try {
          if (formRegister.value.password !== formRegister.value.confirm_password) {
            status = false;
          }
        } catch (e) {
          status = false;
        }
        return status;
      }
  ),
});

const formLogin = ref(new AuthenticationUser());
const formRegister = ref(new AuthenticationUser());
const errors = ref(new AuthenticationUser());
const mainStore = useMainStore()
const siteTitle = useSiteTitle();
const remember = ref(true);

useHead({
  title: computed(() => 'Login - ' + siteTitle.value) ,
  meta: [
    {name: 'description', content: computed(() => siteTitle.value)},
    {name: 'keywords', content: computed(() =>siteTitle.value)},
    { property: 'og:title', content: computed(() =>'Login - ' +siteTitle.value) },
    { property: 'og:type', content: 'article' },
    { property: 'og:description', content: computed(() =>siteTitle.value) },
    { property: 'og:url', content:computed(() =>siteTitle.value) },
  ],
})

const login = async () => {
  let idToken = null;
  try {

    await http.post('/api/login', formLogin.value).then(async (response) => {
      if (remember.value) {
        idToken = useCookie("idToken", {
          maxAge: Number.MAX_SAFE_INTEGER,
        });
      } else {
        idToken = useCookie("idToken");
      }


      idToken.value = response.data.token;
      http.defaults.headers.common.Authorization = "Bearer " + response.data.token;

      await checkUser();

      //
      // navigateTo(router.options?.history?.state?.back?? '/');

    });
  } catch (e) {
    if (e.response?.data?.message) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.message,
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  }

}

const register = async () => {

  try {
    await http.post('/api/register', formRegister.value).then(async (response) => {

      const idToken = useCookie("idToken");

      idToken.value = response.data.token;
      http.defaults.headers.common.Authorization = "Bearer " + response.data.token;
      await checkUser();
      //
      // navigateTo(router.options?.history?.state?.back?? '/');

    });
  } catch (e) {
    if (e.response?.data?.message) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.message,
      })
      errors.value = e.response.data.errors;
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    }
  }


}

const checkUser = async () =>{
  try {
    await http.get('/api/checkUser').then((response) => {
      mainStore.setUser({
        name: response.data.name,
        email: response.data.email,
        permissions: response.data.permissions,
        roles: response.data.roles,
        avatar: 'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93'
      })
      navigateTo(router.options?.history?.state?.back?? '/')
    })
  } catch (e) {
  }
}

onMounted(async () => {
  const idToken = useCookie("idToken");
  http.defaults.headers.common.Authorization = "Bearer "+idToken.value;
  await checkUser();
})
</script>
